import { NgModule } from "@angular/core";
import {
    AppIconPipe,
    AppIconPipeOld,
    AppNamePipe,
    CamelCase,
    SubmissionFormCardInteractivityState,
    CatappultDateAndTimePipe,
    CatappultDatePipe,
    CatappultTimePipe,
    CountryCodeFlagIconUrlPipe,
    CountryIconPipe,
    CountryNamePipe,
    CountryObjectPipe,
    CurrencySimbolPipe,
    DisplayTypePipe,
    FilesDownloadPipe,
    GetLocaleDataFromVersionPipe,
    IconByUidPipe,
    IsApprovedPipe,
    IsCurrencyPipe,
    IsDebitPipe,
    IsPendingPipe,
    IsRejectedPipe,
    isValidNumberPipe,
    LabelByUidPipe,
    ShowPackageInfoPipe,
    SkeletonArrayPipe,
    storeNameListPipe,
    TitleOrPackageNameAppsListFilterPipe,
    ToLocaleStringPipe,
    TotalPipe,
    ShowIfDateDiffGreaterThanDaysNumberPipe,
    ShowIfDateMonthAndYearAreDiff,
    ValidDateFormat,
    IconType,
    CountriesFilterPipe,
    PartnerDisplayNamePipe,
    JoinArrayPrintPipe,
    showDashboardCardPipe,
    FilterPreGeneratedCSVListBySubType,
} from "./pipes";

@NgModule({
    declarations: [
        SkeletonArrayPipe,
        TotalPipe,
        ShowIfDateDiffGreaterThanDaysNumberPipe,
        ShowIfDateMonthAndYearAreDiff,
        IsCurrencyPipe,
        FilesDownloadPipe,
        TitleOrPackageNameAppsListFilterPipe,
        SubmissionFormCardInteractivityState,
        CatappultTimePipe,
        CatappultDatePipe,
        CatappultDateAndTimePipe,
        IsDebitPipe,
        IsApprovedPipe,
        IsPendingPipe,
        IsRejectedPipe,
        DisplayTypePipe,
        ShowPackageInfoPipe,
        ToLocaleStringPipe,
        CountryCodeFlagIconUrlPipe,
        CountryNamePipe,
        AppIconPipeOld,
        LabelByUidPipe,
        IconByUidPipe,
        CountryIconPipe,
        GetLocaleDataFromVersionPipe,
        CountryNamePipe,
        CountryIconPipe,
        AppNamePipe,
        storeNameListPipe,
        CamelCase,
        AppIconPipe,
        CountryObjectPipe,
        isValidNumberPipe,
        CurrencySimbolPipe,
        ValidDateFormat,
        IconType,
        CountriesFilterPipe,
        PartnerDisplayNamePipe,
        JoinArrayPrintPipe,
        showDashboardCardPipe,
        FilterPreGeneratedCSVListBySubType,
    ],
    imports: [],
    exports: [
        SkeletonArrayPipe,
        TotalPipe,
        ShowIfDateDiffGreaterThanDaysNumberPipe,
        ShowIfDateMonthAndYearAreDiff,
        IsCurrencyPipe,
        FilesDownloadPipe,
        TitleOrPackageNameAppsListFilterPipe,
        SubmissionFormCardInteractivityState,
        CatappultTimePipe,
        CatappultDatePipe,
        CatappultDateAndTimePipe,
        IsDebitPipe,
        IsApprovedPipe,
        IsPendingPipe,
        IsRejectedPipe,
        DisplayTypePipe,
        ShowPackageInfoPipe,
        ToLocaleStringPipe,
        CountryCodeFlagIconUrlPipe,
        CountryNamePipe,
        AppIconPipeOld,
        LabelByUidPipe,
        IconByUidPipe,
        CountryIconPipe,
        GetLocaleDataFromVersionPipe,
        CountryNamePipe,
        CountryIconPipe,
        AppNamePipe,
        storeNameListPipe,
        CamelCase,
        AppIconPipe,
        CountryObjectPipe,
        isValidNumberPipe,
        CurrencySimbolPipe,
        ValidDateFormat,
        IconType,
        CountriesFilterPipe,
        PartnerDisplayNamePipe,
        JoinArrayPrintPipe,
        showDashboardCardPipe,
        FilterPreGeneratedCSVListBySubType,
    ],
    providers: [
        TotalPipe,
        ShowIfDateDiffGreaterThanDaysNumberPipe,
        ShowIfDateMonthAndYearAreDiff,
        IsCurrencyPipe,
        FilesDownloadPipe,
        TitleOrPackageNameAppsListFilterPipe,
        SubmissionFormCardInteractivityState,
        CatappultTimePipe,
        CatappultDatePipe,
        CatappultDateAndTimePipe,
        IsDebitPipe,
        IsApprovedPipe,
        IsPendingPipe,
        IsRejectedPipe,
        DisplayTypePipe,
        ShowPackageInfoPipe,
        ToLocaleStringPipe,
        CountryCodeFlagIconUrlPipe,
        CountryNamePipe,
        AppIconPipeOld,
        LabelByUidPipe,
        IconByUidPipe,
        CountryIconPipe,
        GetLocaleDataFromVersionPipe,
        CountryNamePipe,
        CountryIconPipe,
        AppNamePipe,
        storeNameListPipe,
        CamelCase,
        AppIconPipe,
        CountryObjectPipe,
        isValidNumberPipe,
        CurrencySimbolPipe,
        ValidDateFormat,
        IconType,
        CountriesFilterPipe,
        PartnerDisplayNamePipe,
        JoinArrayPrintPipe,
        showDashboardCardPipe,
        FilterPreGeneratedCSVListBySubType,
    ],
})
export class PipesModule {}
