import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppsListService } from "@app/modules/my-apps/apps-list/apps-list.service";
import { AddAppDialogComponent } from "@app/shared/add-app-dialog/add-app-dialog.component";
import { AddAppService } from "@app/shared/add-app-dialog/add-app.service";
import { AccountSubscriptionComponent } from "@app/shared/notifications-dropdown/splash-screens/account-subscription/account-subscription.component";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { BehaviorSubject, forkJoin } from "rxjs";
import { AccountsService } from "./accounts.service";
import { APIRequestsService, RequestParameters } from "./api.requests.service";
import { UserService } from "./user.service";

export interface INotificationItem {
    id: number;
    code: string;
    params: any;
    icon: string;
    type: string;
    text: string;
    time: string;
    read: boolean;
    pointer: boolean;
}
@Injectable({
    providedIn: "root",
})
export class NotificationsService {
    loading = new BehaviorSubject<boolean>(true);
    loadingNotificationsInAnotherAccount = new BehaviorSubject<boolean>(true);
    haveUnreadNotificationsInAnotherAccounts = new BehaviorSubject<boolean>(false);
    haveUnreadNotificationsInLoggedAccount = new BehaviorSubject<boolean>(false);
    loggedAccountNotifications = new BehaviorSubject<any>([]);
    rawLoggedAccountNotifications = new BehaviorSubject<any>([]);
    userAccountsRequests: any[] = [];
    limit = new BehaviorSubject<number>(5);
    offset = new BehaviorSubject<number>(0);
    notificationsPageOffset = new BehaviorSubject<number>(0);
    refreshInterval: number = 120000;
    refreshNotifications = new BehaviorSubject<boolean>(false);
    ignoredNotifications = ["TEST", "NO_LIVE_PACKAGES_REMINDER_SPLASH", "DEVELOPER_BONUS_REMINDER", "WELCOME_TO_CATAPPULT", "FEEDBACK_REQUEST"];

    constructor(
        private requestsService: APIRequestsService,
        private accountService: AccountsService,
        private userService: UserService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private appListService: AppsListService,
        private addAppService: AddAppService,
        private router: Router
    ) {
        this.refreshNotifications.subscribe({
            next: (res) => {
                if (res) {
                    this.loading.next(true);
                    this.loadingNotificationsInAnotherAccount.next(true);
                    this.handleData();
                }
            },
        });

        this.accountService.loadAccountsList().subscribe({
            next: (accountsList: any) => {
                for (let account of accountsList) {
                    this.userAccountsRequests.push(this.loadNotifications(account.uid, 25));
                }
                this.handleData();
            },
        });

        this.userService.languageChanged.subscribe({
            next: (res) => {
                if (res) {
                    const formattedLoggedAccountNotifications = this.formatNotifications(this.rawLoggedAccountNotifications.value);
                    this.loggedAccountNotifications.next(formattedLoggedAccountNotifications);
                }
            },
        });
    }

    loadNotifications(accountUid: string, limit?: number, offset?: number, disabledLoading?: boolean) {
        if (!disabledLoading) {
            this.loading.next(true);
        }

        const notificationsLimit: number = (() => {
            if (limit) {
                return limit;
            }

            return this.limit.value;
        })();
        const notificationsOffset: number = (() => {
            if (offset) {
                return offset;
            }

            return this.offset.value;
        })();
        const url = `/api/accounts/${accountUid}/notifications?limit=${String(notificationsLimit)}&offset=${String(notificationsOffset)}&nocache=1`;
        //const url = `/api/notifications?accountUid=${accountUid}&limit=${String(notificationsLimit)}&offset=${String(notificationsOffset)}&nocache=1`;

        return this.requestsService.get(url);
    }

    formatNotifications(notifications: any) {
        const formattedNotifications = [];
        for (let notification of notifications) {
            let _notification = {
                id: notification.id,
                code: notification.name,
                params: notification.data ? notification.data : null,
                icon: notification.data?.packageName ? notification.data?.packageName : "",
                type: this.getNotificationType(notification.name),
                text: this.getNotificationText(notification.name, notification.data),
                time: this.getNotificationTime(notification.addedTimestamp),
                read: notification.status === "READ",
                pointer: !(notification.status === "READ" && notification.code && notification.code.match("FAILED")),
            };

            if (this.ignoredNotifications.includes(_notification.code)) {
                continue;
            }

            formattedNotifications.push(_notification);

            if (_notification.code === "APP_DISTRIBUTION_MISSING_ACCOUNT_SUBSCRIPTION" && !_notification.read && notification.accountUid === this.userService.loggedAccount.value.uid) {
                this.dialog.open(AccountSubscriptionComponent, {
                    panelClass: "no-padding-dialog",
                    width: "90dvw",
                    maxWidth: "500px",
                    data: {
                        id: notification.id,
                    },
                });
            }
        }
        return formattedNotifications;
    }

    updateNotificationStatus(notificationId: number, status: string) {
        const url = `/api/accounts/${this.userService.loggedAccount.value.uid}/notifications/${notificationId}`;

        const parameters: RequestParameters[] = [];
        parameters.push({ label: "status", data: status });

        this.requestsService.patch(url, parameters).subscribe({
            next: (resp: any) => {},
            error: (error: any) => {},
        });
    }

    getNotificationText(code: string, params: any) {
        if (code === "APK_SUSPENDED" || code === "APK_UNSUSPENDED") {
            return this.translate.instant(`notificationsMessage.${code}`, {
                md5sum: params.md5sum.substring(0, 5),
            });
        }
        return this.translate.instant(`notificationsMessage.${code}`);
    }

    getNotificationType(code: string) {
        const appCodes = [
            "APP_LIVE",
            "CERTIFICATION_DENIED",
            "MIGRATION_FINISHED",
            "MIGRATOR_FINISHED_UNCERTIFIED",
            "MIGRATION_FAILED",
            "MIGRATION_DOWNLOAD_FAILED",
            "AUTO_UPDATE_MIGRATION_FINISHED",
            "CERTIFICATION_MISSING",
            "AUTO_FETCH_NEW_APP_WITH_IAP",
            "AUTO_FETCH_NEW_APP_WITHOUT_IAP",
            "AUTO_FETCH_EXISTING_APP_WITH_IAP",
            "AUTO_FETCH_EXISTING_APP_WITHOUT_IAP",
            "APK_SUSPENDED",
            "APK_UNSUSPENDED",
            "APPLICATION_OWNERSHIP_REQUEST_APPROVED",
            "APPLICATION_OWNERSHIP_REQUEST_NOT_APPROVED",
            "APPLICATION_VERSION_METADATA_UPDATE_REQUEST_APPROVED",
            "APPLICATION_VERSION_METADATA_UPDATE_REQUEST_NOT_APPROVED",
        ];
        let type = "";

        if (appCodes.includes(code)) {
            type = "app";
        }

        return type;
    }

    getNotificationTime(date: string) {
        let time = moment(date).fromNow().replace("ago", "").trim();
        return time === "a day" || time === "a hour" ? time.replace("a ", "1 ") : time;
    }

    doNotificationAction(notification: INotificationItem) {
        const packageName: string = (() => {
            if (notification.params?.package) {
                return notification.params.package;
            }
            if (notification.params?.packageName) {
                return notification.params.packageName;
            }
            return null;
        })();

        const apkMd5sum: string = (() => {
            if (notification.params?.md5sum) {
                return notification.params.md5sum;
            }
            return null;
        })();

        if (
            [
                "APP_LIVE",
                "APPROVED_PENDING_APPROVAL_APK",
                "MIGRATION_FINISHED",
                "AUTO_UPDATE_MIGRATION_FINISHED",
                "MISSING_SPLIT_TYPES",
                "APP_SUBMISSION_SUCCESSFUL",
                "APPLICATION_VERSION_METADATA_UPDATE_REQUEST_APPROVED",
                "APPLICATION_VERSION_METADATA_UPDATE_REQUEST_NOT_APPROVED",
            ].includes(notification.code)
        ) {
            this.goTo(packageName, apkMd5sum);
        }
        if (["APP_SUBMISSION_SUCCESSFUL", "APPLICATION_OWNERSHIP_REQUEST_APPROVED", "APPLICATION_OWNERSHIP_REQUEST_NOT_APPROVED"].includes(notification.code)) {
            this.goTo(packageName);
        }

        if (["MIGRATOR_FINISHED_UNCERTIFIED", "CERTIFICATION_DENIED", "REJECTED_PENDING_APPROVAL_APK", "CERTIFICATION_MISSING"].includes(notification.code)) {
            this.goTo(packageName, apkMd5sum);
        }

        if (["MIGRATION_FAILED"].includes(notification.code)) {
            if (notification.read) {
                return false;
            }

            this.router.navigate([`/myApps/submission`], {
                queryParams: { packageName: packageName },
            });
        }

        if (["MIGRATION_DOWNLOAD_FAILED"].includes(notification.code)) {
            if (notification.read) {
                return false;
            }

            this.addApp();
        }

        if (["AUTO_FETCH_NEW_APP_WITH_IAP", "AUTO_FETCH_NEW_APP_WITHOUT_IAP", "AUTO_FETCH_EXISTING_APP_WITH_IAP", "AUTO_FETCH_EXISTING_APP_WITHOUT_IAP"].includes(notification.code)) {
            if (apkMd5sum) {
                this.goTo(packageName, apkMd5sum);
            } else {
                this.appListService.changeTab("draft");
                this.router.navigate(["/myApps"]);
            }
        }

        if (["APK_SUSPENDED", "APK_UNSUSPENDED"].includes(notification.code)) {
            this.goTo(packageName, apkMd5sum);
        }

        if (["FIAT_WITHDRAW_REQUEST_PROCESSED"].includes(notification.code)) {
            this.router.navigate(["/wallet"]);
        }

        if (["ACCOUNT_SUBSCRIPTION_ACTIVE"].includes(notification.code)) {
            this.router.navigate(["/myApps"]);
        }

        if (["FIAT_WITHDRAW_REQUEST_DECLINED"].includes(notification.code)) {
            this.router.navigate(["/wallet/withdraw"]);
        }

        if (["APP_DISTRIBUTION_MISSING_ACCOUNT_SUBSCRIPTION", "ACCOUNT_SUBSCRIPTION_LAUNCH"].includes(notification.code)) {
            this.dialog.open(AccountSubscriptionComponent, {
                width: "90dvw",
                maxWidth: "500px",
                data: {
                    id: notification.id,
                },
            });
        }

        if (
            [
                "ACCOUNT_SUBSCRIPTION_PAYMENT_FAILED",
                "ACCOUNT_SUBSCRIPTION_EXPIRING",
                "ACCOUNT_SUBSCRIPTION_EXPIRING_SOON",
                "ACCOUNT_SUBSCRIPTION_PAYMENT_FAILED",
                "ACCOUNT_SUBSCRIPTION_EXPIRED",
            ].includes(notification.code)
        ) {
            this.router.navigate(["/payments"]);
        }

        if (["ACCOUNT_SUBSCRIPTION_PAYMENT_REFUNDED", "ACCOUNT_SUBSCRIPTION_PAYMENT_REFUND_FAILED", "ACCOUNT_SUBSCRIPTION_RENEWING"].includes(notification.code)) {
            this.router.navigate(["/settings"]);
        }

        if (["NEW_MARKET_APPLICATION_WITH_BILLING_VERSION_FOUND"].includes(notification.code)) {
            this.router.navigate([`/myApps/submission`], {
                queryParams: { packageName: packageName },
            });
        }

        this.updateNotificationStatus(notification.id, "READ");

        notification.read = true;
        this.haveUnreadNotificationsInLoggedAccount.next(this.loggedAccountNotifications.value.some((notification: any) => !notification.read));
    }

    goTo(packageName: string, apkMd5sum?: string) {
        if (packageName && apkMd5sum) {
            this.router.navigate([`/myApps/${packageName}/${apkMd5sum}`]);
        } else if (packageName) {
            this.router.navigate([`/myApps/${packageName}`]);
        }
    }

    addApp() {
        this.addAppService.dialogAddAppRef = this.dialog.open(AddAppDialogComponent, {
            panelClass: "add-app-dialog",
            width: "90dvw",
            maxWidth: "550px",
            autoFocus: true,
        });
    }

    private handleData() {
        forkJoin(this.userAccountsRequests as []).subscribe({
            next: (res: any) => {
                console.log("HIT!");
                const allUserNotifications = res.flat(1);

                const loggedAccountNotifications = allUserNotifications.filter((notification) => notification.accountUid === this.userService.loggedAccount.value.uid);
                this.rawLoggedAccountNotifications.next(loggedAccountNotifications);

                const formattedLoggedAccountNotifications = this.formatNotifications(loggedAccountNotifications);
                this.loggedAccountNotifications.next(formattedLoggedAccountNotifications);

                this.haveUnreadNotificationsInAnotherAccounts.next(
                    allUserNotifications.some((notification) => notification.status === "NOT_READ" && notification.accountUid !== this.userService.loggedAccount.value.uid)
                );

                this.haveUnreadNotificationsInLoggedAccount.next(
                    allUserNotifications.some((notification) => notification.status === "NOT_READ" && notification.accountUid === this.userService.loggedAccount.value.uid)
                );

                this.loading.next(false);
                this.loadingNotificationsInAnotherAccount.next(false);
            },
        });
    }
}
